import { useEffect } from 'react';
import styled from 'styled-components';

import { PeriodRange, SortDirection } from '../../generated/types';
import { media } from '../../styles/media';

import { useUserContext } from '../../contexts/UserContext';
import { useAuthContext } from '../../contexts/Auth0Context';

import { useLazyGetEvents } from '../../graph/queries/getEvents';
import { useLazyGetBoards } from '../../graph/queries/getBoards';

import { Intro } from '../../components/molecules/Intro/Intro';
import { UpcomingInterview } from '../../components/molecules/UpcomingInterview';
import { DashboardJobs } from './DashboardJobs';
import { SkeletonBlock } from '../../components/atoms/Skeleton';
import { DashboardNotifications } from './DashboardNotifications';
import { useLazyGetTasks } from '../../graph/queries/getTasks';
import { UpcomingTasks } from '../../components/molecules/UpcomingTasks';
import { DashboardHelp } from './DashboardHelp';

const Dashboard = () => {
  const user = useUserContext();
  const { isAuthenticated } = useAuthContext();
  const [getEvents, eventsState] = useLazyGetEvents({
    variables: {
      where: { userUuid: user?.uuid || '', isDeleted: false, periodRange: PeriodRange.Week },
      sort: { direction: SortDirection.Asc, field: 'startAt' },
    },
    fetchPolicy: 'cache-and-network',
  });
  const [getBoards, boardsState] = useLazyGetBoards({
    variables: {
      where: {
        isDeleted: false,
      },
      sort: { direction: SortDirection.Desc, field: 'datetime' },
      limit: 1,
    },
    fetchPolicy: 'cache-and-network',
  });
  const [getTasks, _tasksState] = useLazyGetTasks({
    variables: {
      where: { userUuid: user?.uuid || '', isDeleted: false, periodRange: PeriodRange.Week },
      sort: { direction: SortDirection.Desc, field: 'datetime' },
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (isAuthenticated && user?.uuid) {
      getEvents();
      getBoards();
      getTasks();
    }
  }, [isAuthenticated, user]);

  const eventsCount = eventsState?.data?.events.length || 0;
  const userUuid = user?.uuid || '';
  const canSeeTasks = user?.isMember || false;

  return (
    <Wrapper>
      {/* <pre>{JSON.stringify({ eventsState: eventsState?.data?.events }, null, 2)}</pre> */}
      <MainContainer>
        {isAuthenticated && <Intro latestBoard={boardsState?.data?.boards[0]} user={user} eventsCount={eventsCount} />}
      </MainContainer>

      <SidebarContainer>
        {userUuid ? (
          <UpcomingInterview getEvents={getEvents} />
        ) : (
          <SkeletonBlock style={{ height: '260px', marginBottom: '32px' }} />
        )}

        {canSeeTasks && (
          <>
            {userUuid ? (
              <UpcomingTasks getTasks={getTasks} />
            ) : (
              <SkeletonBlock style={{ height: '160px', marginBottom: '32px' }} />
            )}
          </>
        )}
        <DashboardHelp />
      </SidebarContainer>

      <JobsContainer>
        {userUuid ? (
          <DashboardJobs userUuid={userUuid} />
        ) : (
          <SkeletonBlock style={{ height: '160px', marginBottom: '32px' }} />
        )}
      </JobsContainer>

      <NotificationsContainer>
        {userUuid ? (
          <DashboardNotifications userUuid={userUuid} />
        ) : (
          <SkeletonBlock style={{ height: '160px', marginBottom: '32px' }} />
        )}
      </NotificationsContainer>
    </Wrapper>
  );
};

export default Dashboard;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px; // Also valid for the grid layout
  margin-top: 64px;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr;

    ${media.from_laptop`
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas:
        'main main aside'
        'jobs notifications aside'
    `}
  }
`;

const MainContainer = styled.div`
  ${media.from_laptop`
    grid-area: main;
  `}
`;

const SidebarContainer = styled.div`
  ${media.from_laptop`
    grid-area: aside;
  `}
  display: flex;
  flex-direction: column;
`;

const JobsContainer = styled.div`
  ${media.from_laptop`
    grid-area: jobs;
  `}
`;

const NotificationsContainer = styled.div`
  ${media.from_laptop`
    grid-area: notifications;
  `}
`;
