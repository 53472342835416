import { gql, useQuery, useLazyQuery, QueryHookOptions } from '@apollo/client';

import { Query, QueryEventsArgs } from '../../generated/types';

export const GET_EVENTS = gql`
  query Events($where: EventsInputWhere!, $sort: InputSort!, $limit: Int) {
    events(where: $where, sort: $sort, limit: $limit) {
      uuid
      description
      startAt
      endAt
      type
      createdAt
      updatedAt

      job {
        uuid
        userUuid
        boardUuid
        agencyName
        company
        jobTitle
        jobUrl
        status

        contacts {
          uuid
          fullName
          position
          email
          phoneNumber
          linkedin
        }
      }
    }
  }
`;

export const useGetEvents = (options?: QueryHookOptions<Pick<Query, 'events'>, QueryEventsArgs>) =>
  useQuery<Pick<Query, 'events'>, QueryEventsArgs>(GET_EVENTS, options);

export const useLazyGetEvents = (options?: QueryHookOptions<Pick<Query, 'events'>, QueryEventsArgs>) =>
  useLazyQuery<Pick<Query, 'events'>, QueryEventsArgs>(GET_EVENTS, options);
