import { CreateResumeStepsProps } from './CreateResumeSteps.types';
import { Container, Step, Steps } from './CreateResumeSteps.styles';
import { Colors } from '../../../styles/colors';
import { H4 } from '../../atoms/Typography';

export const CreateResumeSteps = ({
  children,
  onActionClick,
  isShort = true,
}: React.PropsWithChildren<CreateResumeStepsProps>) => {
  return (
    <Container>
      <Steps>
        <Step $backgroundColor={Colors.PrimaryLightest}>
          <div>
            <H4>Create a Jobs Board</H4>
            <p>
              {isShort
                ? `Create a Jobs Board and access the Resume section on your Jobs Board to get started.`
                : `Your journey starts with a custom Jobs Board. Create one to simplify your hiring process and manage all
              your documents in one place, including your resumes. Navigate to the Resume section in your Jobs Board and
              get started.`}
            </p>
          </div>
        </Step>
        <Step $backgroundColor={Colors.Primary}>
          <div>
            <H4>Add your information</H4>
            <p>
              {isShort
                ? `Start from scratch or upload your existing resume. Simply fill in the details with our intuitive form.`
                : `Start from scratch or upload your existing resume. Simply fill in the details with our intuitive form, and
                voila! Feeling unsure? Take advantage of our AI assistant to enhance your writing.`}
            </p>
          </div>
        </Step>
        <Step $backgroundColor={Colors.PrimaryDarkest}>
          <div>
            <H4>Personalize</H4>
            <p>
              {isShort
                ? `Tailor your resume to perfection with hundreds of customizable options. Select from a range of templates
                and styles to make your resume uniquely yours.`
                : `Tailor your resume to perfection with hundreds of customizable options. Select from a range of templates
                and styles to make your resume uniquely yours.  When you're satisfied, simply click the button, and as a JobsBoard.io member, you'll promptly receive your
                impeccably crafted, ready-to-submit resume.`}
            </p>
          </div>
        </Step>
      </Steps>
    </Container>
  );
};
