import styled from 'styled-components';
import { useEffect, useState } from 'react';

import { formatUrl } from '../../../utils/url';
import { Urls } from '../../../routes';
import { convertEnumToString } from '../../../utils/enum';
import { PeriodRange, SortDirection, Task } from '../../../generated/types';

import { useRouter } from '../../../hooks/useRouter';
import { useUserContext } from '../../../contexts/UserContext';

import { UpcomingTasksProps } from './UpcomingTasks.types';
import { Paper } from '../../atoms/Paper';
import { Copy } from '../../atoms/Typography';
import { Colors } from '../../../styles/colors';
import { IconButton } from '../../atoms/Icons/Icon';
import { TinyLoader } from '../../atoms/Loader';
import {
  InterviewDay,
  More,
  TaskCompanyName,
  TaskDescription,
  TaskWrapper,
  TaskWrapperLeft,
  TaskWrapperRight,
  TasksWrapper,
} from './UpcomingTasks.styles';
import { AlertIcon, ChevronLeftIcon, ChevronRightIcon } from '../../atoms/Icons';
import { Pill } from '../../atoms/Pill';

const TASKS_DIPLAY_LIMIT = 7;

export const UpcomingTasks: React.FC<any> = ({ getTasks }: React.PropsWithChildren<UpcomingTasksProps>) => {
  const user = useUserContext();
  const { navigate } = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [tasks, setTasks] = useState<any>([]);
  const [periodRange, setPeriodRange] = useState<PeriodRange>(PeriodRange.Week);

  useEffect(() => {
    async function getTheEvents() {
      try {
        setIsLoading(true);

        const { data } = await getTasks({
          variables: {
            where: { userUuid: user?.uuid || '', isDeleted: false, isCompleted: false, periodRange },
            sort: { direction: SortDirection.Asc, field: 'dueDate' },
          },
          fetchPolicy: 'cache-and-network',
        });

        const tasks =
          data?.tasks.map((task: Task) => ({
            title: task.title,
            dueDate: task.dueDate,
            description: task.description,
            priority: task.priority,
            companyName: task.job?.company || '',
            isFullDay: task.isFullDay,
            isOverdue: task.isOverdue,
            userUuid: task?.job?.userUuid,
            boardUuid: task?.job.boardUuid,
            jobUuid: task?.job?.uuid,
          })) || [];

        setTasks(tasks);
      } catch (error) {
        console.error('error', error);
      } finally {
        setIsLoading(false);
      }
    }
    getTheEvents();
  }, [periodRange]);

  const handlePeriodRangeChange = (action: 'reduce' | 'extend') => {
    if (action === 'extend' && periodRange === PeriodRange.Day) {
      setPeriodRange(PeriodRange.Week);
    }
    if (action === 'extend' && periodRange === PeriodRange.Week) {
      setPeriodRange(PeriodRange.Month);
    }
    if (action === 'extend' && periodRange === PeriodRange.Month) {
      setPeriodRange(PeriodRange.Overdue);
    }
    if (action === 'extend' && periodRange === PeriodRange.Overdue) {
      setPeriodRange(PeriodRange.Day);
    }
    if (action === 'reduce' && periodRange === PeriodRange.Overdue) {
      setPeriodRange(PeriodRange.Month);
    }
    if (action === 'reduce' && periodRange === PeriodRange.Day) {
      setPeriodRange(PeriodRange.Overdue);
    }
    if (action === 'reduce' && periodRange === PeriodRange.Week) {
      setPeriodRange(PeriodRange.Day);
    }
    if (action === 'reduce' && periodRange === PeriodRange.Month) {
      setPeriodRange(PeriodRange.Week);
    }
  };

  const onActionClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    userUuid: string,
    boardUuid: string,
    jobUuid: string
  ) => {
    event.stopPropagation();
    navigate(`/${Urls.Jobs}/${formatUrl(Urls.JobView, { userUuid, boardUuid, jobUuid })}#tasks`);
  };

  let body = <></>;
  if (isLoading) {
    body = (
      <>
        <TinyLoader />
      </>
    );
  } else if (tasks.length === 0) {
    body = (
      <TasksWrapper>
        <Copy isItalic={true} color={Colors.BlackLightest}>
          No Tasks Pending
        </Copy>
      </TasksWrapper>
    );
  } else {
    const tasksRange = tasks.slice(0, TASKS_DIPLAY_LIMIT);
    const hasMore = tasks.length > TASKS_DIPLAY_LIMIT;
    body = (
      <>
        {/* <pre>{JSON.stringify(tasks, null, 2)}</pre> */}
        <TasksWrapper>
          {tasksRange.map((task: Task) => {
            const date = new Date(task.dueDate);

            const isToday = date.toDateString() === new Date().toDateString();
            const isTomorrow =
              date.toDateString() === new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toDateString();
            const isFullDay = task.isFullDay;
            const priority = getPriority(task.priority);

            let dueDate = new Date(task.dueDate).toLocaleString('en-UK', {
              day: 'numeric',
              month: 'short',
            });
            let dueTime = isFullDay
              ? ''
              : new Date(task.dueDate).toLocaleString('en-UK', {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                });
            if (isToday) {
              dueDate = 'Today';
            } else if (isTomorrow) {
              dueDate = 'Tomorrow';
            }

            return (
              <TaskWrapper
                key={task.uuid}
                onClick={(event) => onActionClick(event, task.userUuid, task.boardUuid, task.jobUuid)}
              >
                <TaskWrapperLeft>
                  <TaskCompanyName>{task.companyName}</TaskCompanyName>
                  {task.description && <TaskDescription>{task.description}</TaskDescription>}
                </TaskWrapperLeft>
                <TaskWrapperRight>
                  <InterviewDay>
                    {task.isOverdue ? <AlertIcon size={1.1} color={Colors.ContrastDarkest} /> : null}
                    {dueDate} {dueTime}
                  </InterviewDay>
                  {priority}
                </TaskWrapperRight>
              </TaskWrapper>
            );
          })}
        </TasksWrapper>
        {hasMore && (
          <More>
            {tasks.length - TASKS_DIPLAY_LIMIT} more task
            {tasks.length - TASKS_DIPLAY_LIMIT > 1 ? 's' : ''}
          </More>
        )}
      </>
    );
  }

  return (
    <Paper elevation={3}>
      <Header>
        <IconButton onClick={() => handlePeriodRangeChange('reduce')}>
          <ChevronLeftIcon />
        </IconButton>
        <HeaderLabel>{convertEnumToString('PeriodRange', periodRange)} tasks</HeaderLabel>
        <IconButton onClick={() => handlePeriodRangeChange('extend')}>
          <ChevronRightIcon />
        </IconButton>
      </Header>
      {/* <pre>{JSON.stringify(periodRange, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(tasks, null, 2)}</pre> */}
      {body}
    </Paper>
  );
};

const getPriority = (priority?: string | null): React.ReactNode => {
  let priorityElement = <></>;
  switch (priority) {
    case 'CRITICAL':
      priorityElement = (
        <Pill isSmall={true} fillColor={Colors.ContrastDarkest} color={Colors.Black}>
          High
        </Pill>
      );
      break;
    case 'HIGH':
      priorityElement = (
        <Pill isSmall={true} fillColor={Colors.Contrast} color={Colors.Black}>
          High
        </Pill>
      );
      break;
    case 'MEDIUM':
      priorityElement = (
        <Pill isSmall={true} fillColor={Colors.Primary} color={Colors.Black}>
          Medium
        </Pill>
      );
      break;
    case 'LOW':
      priorityElement = (
        <Pill isSmall={true} fillColor={Colors.PrimaryLightest} color={Colors.Black}>
          Low
        </Pill>
      );
      break;
    default:
      break;
  }
  return priorityElement;
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const HeaderLabel = styled.div`
  color: ${Colors.Primary};
  font-weight: bold;
`;
