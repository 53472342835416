import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';
import { CreateResumeStepsProps } from './CreateResumeSteps.types';
import { BackgroundColor } from '@styled-icons/foundation';

export const Container = styled.div<CreateResumeStepsProps>`
  /* border: 3px solid ${Colors.Primary};

  ${({ isCreateResumeSteps }: CreateResumeStepsProps) => {
    if (isCreateResumeSteps) {
      return css`
        background-color: ${Colors.Contrast};
      `;
    }
  }};

  ${media.from_tablet`
    padding: 10px 10px 5px;
  `} */
`;

export const Step = styled.li<{ $backgroundColor: string }>`
  display: flex;
  padding: 8px;
  border-radius: 8px;

  ${({ $backgroundColor }) => css`
    background-color: ${$backgroundColor};
  `}

  /* &:first-child {
    transform: translateX(0px);
  }

  &:nth-child(2) {
    transform: translateX(16px);
  }

  &:nth-child(3) {
    transform: translateX(32px);
  }

  &:nth-child(4) {
    transform: translateX(48px);
  } */

  &::before {
    counter-increment: step;
    content: counter(step);
    width: 80px;
    font-size: 6rem;
    line-height: 0.7;
    text-align: center;
    color: ${Colors.Black};
    border-right: 3px solid ${Colors.ContrastDarkest};
    margin-right: 16px;
    padding-right: 16px;
    margin-top: 72px;
  }
`;

export const Steps = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  counter-reset: step;
`;
