import styled from 'styled-components';
import { Colors } from '../../../styles/colors';

export const TasksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const TaskWrapper = styled.div`
  border: 1px solid ${Colors.Contrast};
  background-color: ${Colors.ContrastTransparent};
  padding: 8px;
  margin-bottom: 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${Colors.PrimaryLightest};
  }
`;

export const TaskWrapperLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TaskWrapperRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const TaskCompanyName = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-weight: bold;
`;

export const TaskDescription = styled.div`
  font-style: italic;
  font-size: 0.8rem;
`;

export const InterviewDay = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  color: ${Colors.Contrast};
  font-weight: bold;
`;

export const More = styled.div`
  color: ${Colors.GreyLightest};
  font-weight: bold;
  font-size: 0.7rem;
`;
