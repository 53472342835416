import styled from 'styled-components';
import { useEffect, useState } from 'react';

import { formatUrl } from '../../../utils/url';
import { Urls } from '../../../routes';
import { convertEnumToString } from '../../../utils/enum';
import { Event, PeriodRange, SortDirection } from '../../../generated/types';

import { useRouter } from '../../../hooks/useRouter';
import { useUserContext } from '../../../contexts/UserContext';

import { UpcomingInterviewProps } from './UpcomingInterview.types';
import { Paper } from '../../atoms/Paper';
import { Copy } from '../../atoms/Typography';
import { Colors } from '../../../styles/colors';
import { ChevronLeftIcon, ChevronRightIcon } from '../../atoms/Icons';
import { IconButton } from '../../atoms/Icons/Icon';
import { TinyLoader } from '../../atoms/Loader';
const INTERVIEWS_DIPLAY_LIMIT = 7;

export const UpcomingInterview: React.FC<any> = ({ getEvents }: React.PropsWithChildren<UpcomingInterviewProps>) => {
  const user = useUserContext();
  const { navigate } = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [interviews, setInterviews] = useState<any>([]);
  const [periodRange, setPeriodRange] = useState<PeriodRange>(PeriodRange.Week);

  useEffect(() => {
    async function getTheEvents() {
      try {
        setIsLoading(true);

        const { data } = await getEvents({
          variables: {
            where: { userUuid: user?.uuid || '', isDeleted: false, periodRange },
            sort: { direction: SortDirection.Asc, field: 'startAt' },
          },
          fetchPolicy: 'cache-and-network',
        });

        const interviews =
          data?.events.map((event: Event) => ({
            date: event.startAt,
            startTime: event.startAt,
            endTime: event.endAt,
            agencyName: event.job?.agencyName || '',
            companyName: event.job?.company || '',
            jobUuid: event.job?.uuid || '',
            jobTitle: event.job?.jobTitle || '',
            boardUuid: event.job?.boardUuid || '',
            userUuid: event.job?.userUuid || '',
          })) || [];

        setInterviews(interviews);
      } catch (error) {
        console.error('error', error);
      } finally {
        setIsLoading(false);
      }
    }
    getTheEvents();
  }, [periodRange]);

  const handlePeriodRangeChange = (action: 'reduce' | 'extend') => {
    if (action === 'extend' && periodRange === PeriodRange.Day) {
      setPeriodRange(PeriodRange.Week);
    }
    if (action === 'extend' && periodRange === PeriodRange.Week) {
      setPeriodRange(PeriodRange.Month);
    }
    if (action === 'extend' && periodRange === PeriodRange.Month) {
      setPeriodRange(PeriodRange.Day);
    }
    if (action === 'reduce' && periodRange === PeriodRange.Day) {
      setPeriodRange(PeriodRange.Month);
    }
    if (action === 'reduce' && periodRange === PeriodRange.Week) {
      setPeriodRange(PeriodRange.Day);
    }
    if (action === 'reduce' && periodRange === PeriodRange.Month) {
      setPeriodRange(PeriodRange.Week);
    }
  };

  const onActionClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    userUuid: string,
    boardUuid: string,
    jobUuid: string
  ) => {
    event.stopPropagation();
    navigate(`/${Urls.Jobs}/${formatUrl(Urls.JobView, { userUuid, boardUuid, jobUuid })}`);
  };

  let body = <></>;
  if (isLoading) {
    body = <TinyLoader />;
  } else if (interviews.length === 0) {
    body = (
      <InterviewWrapper>
        <Copy isItalic={true} color={Colors.BlackLightest}>
          No Upcoming Interviews
        </Copy>
      </InterviewWrapper>
    );
  } else {
    const interviewsRange = interviews.slice(0, INTERVIEWS_DIPLAY_LIMIT);
    const hasMore = interviews.length > INTERVIEWS_DIPLAY_LIMIT;
    body = (
      <>
        {interviewsRange.map((interview: any) => {
          const date = new Date(interview.date);
          const startTime = new Date(interview.startTime).toLocaleString('en-UK', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          });
          const endTime = new Date(interview.endTime).toLocaleString('en-UK', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          });
          const isToday = date.toDateString() === new Date().toDateString();
          const isTomorrow =
            date.toDateString() === new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toDateString();
          return (
            <InterviewWrapper
              onClick={(event) => onActionClick(event, interview.userUuid, interview.boardUuid, interview.jobUuid)}
              key={interview.uuid}
            >
              <InterviewerContact>
                <InterviewerContactCompanyName>{interview.companyName}</InterviewerContactCompanyName>
                <InterviewerContactJobTitle>{interview.jobTitle}</InterviewerContactJobTitle>
                <InterviewerContactAgencyName>{interview.agencyName}</InterviewerContactAgencyName>
              </InterviewerContact>
              <InterviewerDate>
                <InterviewTime>
                  {startTime} - {endTime}
                </InterviewTime>
                <InterviewDay>
                  {isToday && <Today>Today</Today>}
                  {isTomorrow && <Tomorrow>Tomorrow</Tomorrow>}
                  {date.toLocaleDateString('en-UK', {
                    year: 'numeric',
                    weekday: 'short',
                    month: 'short',
                    day: 'numeric',
                  })}
                </InterviewDay>
              </InterviewerDate>
            </InterviewWrapper>
          );
        })}
        {hasMore && (
          <More>
            {interviews.length - INTERVIEWS_DIPLAY_LIMIT} more event
            {interviews.length - INTERVIEWS_DIPLAY_LIMIT > 1 ? 's' : ''}
          </More>
        )}
      </>
    );
  }

  return (
    <Paper elevation={3}>
      <Header>
        <IconButton onClick={() => handlePeriodRangeChange('reduce')}>
          <ChevronLeftIcon />
        </IconButton>
        <HeaderLabel>{convertEnumToString('PeriodRange', periodRange)} events</HeaderLabel>
        <IconButton onClick={() => handlePeriodRangeChange('extend')}>
          <ChevronRightIcon />
        </IconButton>
      </Header>
      {body}
    </Paper>
  );
};

const Tomorrow = styled.span`
  color: ${Colors.Primary};
  font-weight: 900;
`;

const Today = styled.span`
  color: ${Colors.PrimaryDarkest};
  font-weight: 900;
`;

const InterviewWrapper = styled.div`
  border: 1px solid ${Colors.Contrast};
  background-color: ${Colors.ContrastTransparent};
  padding: 8px;
  margin-bottom: 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${Colors.PrimaryLightest};
  }
`;

const InterviewerContact = styled.div``;

const InterviewerContactCompanyName = styled.div`
  font-weight: bold;
`;

const InterviewerContactJobTitle = styled.div`
  font-style: italic;
  font-size: 0.8rem;
`;

const InterviewerContactAgencyName = styled.div`
  font-style: italic;
  font-size: 0.8rem;
`;

const InterviewerDate = styled.div``;

const InterviewDay = styled.div`
  color: ${Colors.Contrast};
  font-weight: bold;
`;

const More = styled.div`
  color: ${Colors.GreyLightest};
  font-weight: bold;
  font-size: 0.7rem;
`;

const InterviewTime = styled.div`
  color: ${Colors.Contrast};
  font-weight: bold;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const HeaderLabel = styled.div`
  color: ${Colors.Primary};
  font-weight: bold;
`;
