import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { CreateResumeSteps } from '../../components/organisms/CreateResumeSteps';
import { JobStatus, SortDirection } from '../../generated/types';

import blankCanvasImage from '../../assets/vectors/man-character14.svg';
import { formatUrl } from '../../utils/url';
import { Colors } from '../../styles/colors';
import { convertEnumToString } from '../../utils/enum';
import { Urls } from '../urls';

import { useLazyGetJobs } from '../../graph/queries/getJobs';

import { Paper } from '../../components/atoms/Paper';
import { H4 } from '../../components/atoms/Typography';
import { ErrorBoundary } from '../../components/molecules/ErrorBoundary';
import {
  ApplyFullIcon,
  DatabaseIcon,
  DeleteFullIcon,
  HandShakeFullIcon,
  OfficeBuildingIcon,
  SuitcaseFullIcon,
  TrophyFullIcon,
  ViewIcon,
  WishFullIcon,
} from '../../components/atoms/Icons';
import { SmallLoader } from '../../components/atoms/Loader';
import { Placeholder } from '../../components/atoms/Placeholder';
import { Size } from '../../components/atoms/Placeholder/Placeholder';
import { Tooltip } from '../../components/atoms/Tooltip';

export const DashboardHelp = (): React.ReactNode => {
  // const [getJobs, { data, loading }] = useLazyGetJobs({
  //   variables: {
  //     where: {
  //       userUuid,
  //       isDeleted: false,
  //       isRejected: false,
  //     },
  //     sort: { direction: SortDirection.Desc, field: 'datetime' },
  //     limit: 5,
  //   },
  // });

  // useEffect(() => {
  //   async function fetchJobs() {
  //     await getJobs();
  //   }

  //   fetchJobs();
  // }, [userUuid]);

  // const jobs = data?.jobs.filter((job) => job.isDeleted === false) || [];

  // let children = <SmallLoader />;

  // if (!loading) {
  //   if (jobs.length === 0) {
  //     children = <Placeholder icon={blankCanvasImage} iconAlt="404" iconSize={Size.SMALL} title="" />;
  //   } else {
  //     children = (
  //       <Wrapper>
  //         {jobs.map((job) => {
  //           const boardUuid = job.board?.uuid || '';
  //           const jobUuid = job.uuid || '';

  //           if (!jobUuid || !boardUuid) {
  //             return null;
  //           }

  //           return (
  //             <ExtendLink
  //               key={job.uuid}
  //               to={`/${Urls.Jobs}/${formatUrl(Urls.JobView, { userUuid, boardUuid, jobUuid })}`}
  //             >
  //               <ItemWrapper>
  //                 {/* <pre>{JSON.stringify(job, null, 2)}</pre> */}
  //                 <>{getStatus(job.status)}</>
  //                 <Description>
  //                   <JobTitle>{job.jobTitle}</JobTitle>
  //                   <LineWrapper>
  //                     <OfficeBuildingIcon size={0.5} />
  //                     <Company>{job.company}</Company>
  //                   </LineWrapper>
  //                 </Description>
  //                 <ViewIcon />
  //               </ItemWrapper>
  //             </ExtendLink>
  //           );
  //         })}
  //       </Wrapper>
  //     );
  //   }
  // }

  return (
    <ErrorBoundary message="\Dashboard DashboardJobs">
      <Paper elevation={1}>
        <H4>Need a Resume?</H4>
        <CreateResumeSteps />
      </Paper>
    </ErrorBoundary>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

const ExtendLink = styled(Link)`
  text-decoration: none;
  color: ${Colors.GreyDarkest};
`;

const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  background-color: ${Colors.ContrastUltraLightest};
  padding: 8px 16px;
  border-radius: 8px;
`;

const LineWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 4px;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const JobTitle = styled.div`
  font-weight: 900;
`;

const Company = styled.div`
  font-weight: 400;
  font-size: 0.8rem;
  color: ${Colors.GreyDark};
`;

function getStatus(status: JobStatus): React.ReactNode {
  let icon = <></>;
  switch (status) {
    case JobStatus.Scrapped:
      icon = <DatabaseIcon color={Colors.Contrast} size={2} />;
      break;
    case JobStatus.Wishlist:
      icon = <WishFullIcon color={Colors.Contrast} size={2} />;
      break;
    case JobStatus.Applied:
      icon = <ApplyFullIcon color={Colors.Contrast} size={2} />;
      break;
    case JobStatus.Interview:
      icon = <SuitcaseFullIcon color={Colors.Contrast} size={2} />;
      break;
    case JobStatus.Offer:
      icon = <TrophyFullIcon color={Colors.Contrast} size={2} />;
      break;
    case JobStatus.Accepted:
      icon = <HandShakeFullIcon color={Colors.Contrast} size={2} />;
      break;
    case JobStatus.Closed:
      icon = <DeleteFullIcon color={Colors.Contrast} size={2} />;
      break;
  }
  const label = convertEnumToString('JobStatus', status);
  return <Tooltip title={icon}>{label}</Tooltip>;
}

type DashboardJobsProps = {
  userUuid: string;
};
