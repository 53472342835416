import styled from 'styled-components';
import { Link } from 'react-router-dom';

import manCharacter from '../../../assets/vectors/man-character2.svg';

import { Board, User } from '../../../generated/types';

import { media } from '../../../styles/media';
import { Colors } from '../../../styles/colors';
import { Urls } from '../../../routes';
import { useRouter } from '../../../hooks/useRouter';

import { Copy, H2 } from '../../atoms/Typography';
import { formatUrl } from '../../../utils/url';
import { PrimaryButton } from '../../atoms/Button/Buttons';
import { AddIcon } from '../../atoms/Icons';
import { Paper } from '../../atoms/Paper';

export const Intro = ({ user, eventsCount, latestBoard }: IntroProps) => {
  const { navigate } = useRouter();

  const userUuid = user?.uuid || '';
  const boardUuid = latestBoard?.uuid;
  const boardTitle = latestBoard?.title;
  const name = user?.name || user?.nickname || 'you';

  return (
    <Paper elevation={2}>
      <Wrapper>
        <ContentWrapper>
          <H2>Howdy, {name}!</H2>
          <Copy marginBottom={20}>
            You have <ColorSpan>{eventsCount}</ColorSpan> upcoming interviews this week.
          </Copy>
          {latestBoard && boardUuid && (
            <Copy marginBottom={20}>
              Currently, you are actively searching for a{' '}
              <LinkExtented to={`/${Urls.Boards}/${formatUrl(Urls.BoardView, { userUuid, boardUuid })}`}>
                <ColorSpan>{boardTitle}</ColorSpan>{' '}
              </LinkExtented>
              position.
            </Copy>
          )}
          {latestBoard ? (
            <Copy>
              Check all your active <LinkExtented to={`/${Urls.Boards}`}>Jobs Boards</LinkExtented>
            </Copy>
          ) : (
            <>
              <Copy marginBottom={20}>
                You can create a new <LinkExtented to={`/${Urls.Boards}`}>Jobs Board</LinkExtented> to start tracking
                your Job Applications.
              </Copy>
              <Copy marginBottom={20}>Let's you get started...</Copy>
              <ButtonsWrapper>
                <PrimaryButton
                  inline={true}
                  iconRight={<AddIcon size={1} color={Colors.White} />}
                  onClick={() => {
                    navigate(`/${Urls.Boards}`);
                  }}
                >
                  Create a Jobs Board
                </PrimaryButton>
              </ButtonsWrapper>
            </>
          )}
        </ContentWrapper>
        <ImageWrapper>
          <ImageExtended alt={'businessman'} src={manCharacter} />
        </ImageWrapper>
      </Wrapper>
    </Paper>
  );
};

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;

  ${media.from_laptop`
    justify-content: flex-start;
  `}
`;

const Wrapper = styled.div`
  display: flex;
`;

const ImageExtended = styled.img`
  position: absolute;
  top: -20px;
  right: 0;
  ${media.from_mobile`
    top: -80px;
  `}
  ${media.from_laptop`
    top: -120px;
  `}
  ${media.from_large_laptop`
    top: -150px;
  `}
`;

const LinkExtented = styled(Link)`
  color: ${Colors.ContrastDarkest};
  font-weight: 600;
  text-decoration: none;
`;

const ColorSpan = styled.span`
  color: ${Colors.Primary};
  font-weight: 600;
`;

const ContentWrapper = styled.div`
  flex: 4;
`;

const ImageWrapper = styled.div`
  flex: 1;
  display: none;
  position: relative;

  ${media.from_mobile`
    display: block;
  `}
`;

type IntroProps = {
  user: User | null;
  eventsCount: number;
  latestBoard?: Board;
};
