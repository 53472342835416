import { gql, useQuery, useLazyQuery, QueryHookOptions } from '@apollo/client';

import { Query, QueryTasksArgs } from '../../generated/types';

export const GET_TASKS = gql`
  query Tasks($where: TasksInputWhere!, $sort: InputSort!, $limit: Int) {
    tasks(where: $where, sort: $sort, limit: $limit) {
      uuid
      title
      description
      dueDate
      priority
      isFullDay
      isOverdue

      job {
        jobTitle
        company
        uuid
        userUuid
        boardUuid
      }
    }
  }
`;

export const useGetTasks = (options?: QueryHookOptions<Pick<Query, 'tasks'>, QueryTasksArgs>) =>
  useQuery<Pick<Query, 'tasks'>, QueryTasksArgs>(GET_TASKS, options);

export const useLazyGetTasks = (options?: QueryHookOptions<Pick<Query, 'tasks'>, QueryTasksArgs>) =>
  useLazyQuery<Pick<Query, 'tasks'>, QueryTasksArgs>(GET_TASKS, options);
